import React from "react";

import Layout from "../components/Layout";
import ContentTitle from "../components/ContentTitle";
import Education from "../components/Education";
import Volunteer from "../components/Volunteer";
import Skills from "../components/Skills";

import workExperience from "../data/workExperience";
import volunteerExperience from "../data/volunteerExperience";
import educationExperience from "../data/education";
import skillsData from "../data/skillsData";
import ResumeSection from "../components/ResumeSection";
import certifications from "../data/certifications";
import CertificationSection from "../components/Certification";

const Resume = () => (
  <Layout>
    <ContentTitle title="Resume" />
    <div className="content-body">
      <ResumeSection title="Experience" data={workExperience} />
      <CertificationSection title="Certifications" data={certifications} />
      <Volunteer title="Volunteer Work" data={volunteerExperience} />
      <Education data={educationExperience} />
      <Skills data={skillsData} />
    </div>
  </Layout>
);

export default Resume;
