import React from "react";
import ResumeCard from "./ResumeCard";

const ResumeSection = (props) => {
  return (
    <div>
      <h2>{props.title}</h2>
      <div className="card-section">
        {props.data.map((position) => (
          <ResumeCard key={position.company} data={position} />
        ))}
      </div>
    </div>
  );
};

export default ResumeSection;
