import React from "react";
import { useQuery } from "react-query";
import axios from "axios";

import CircularProgress from "@mui/material/CircularProgress";

import BlogCard from "./BlogCard";

const ENDPOINT = "https://gql.hashnode.com";
const GET_PUBLICATION_QUERY = `
    query Publication {
      publication(host: "blog.kthorpe.co") {
        posts(first: 5) {
          edges {
            node {
              title
              brief
              slug
              publishedAt
              coverImage {
                url
              }
            }
          }
        }
      }
    }
  `;

const Blog = () => {
  const { data, isLoading, error } = useQuery("posts", () => {
    return axios({
      url: ENDPOINT,
      method: "POST",
      data: {
        query: GET_PUBLICATION_QUERY
      }
    }).then((response) => response.data.data);
  });

  if (isLoading) return <CircularProgress />;
  if (error) return <div>{error.message}</div>;

  return (
    <div className="card-section">
      <BlogCard
        title="Understanding the Serverless Framework and Event-Driven Architecture"
        brief="In cloud computing architecture, the question of “Who manages what?” is important to ask. Whether the cloud provider or customer manages infrastructure..."
        date="Tue Jul 05 2022"
        link="https://www.proofpoint.com/us/blog/engineering-insights/understanding-serverless-framework-and-event-driven-architecture"
        imgUrl={require("../images/cloud.png")}
      />

      {data.publication.posts.edges?.map(({ node: post }, i) => (
        <BlogCard
          key={i}
          title={post.title}
          brief={post.brief}
          date={post.publishedAt}
          link={`https://blog.kthorpe.co/${post.slug}`}
          imgUrl={`${post.coverImage.url}`}
        />
      ))}
    </div>
  );
};

export default Blog;
