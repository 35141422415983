import React from "react";

function getDate(date) {
  return new Date(date).toDateString();
}

const BlogCard = (props) => {
  return (
    <div className="blog-card">
      <div className="blog-card-content">
        <div className="blog-card-image">
          <a href={props.link}>
            <img src={props.imgUrl} alt="Blog cover" className="blog-image" />
          </a>
        </div>
        <div className="blog-card-text">
          <h3>
            <a href={props.link} className="blog-header">
              {props.title}
            </a>
          </h3>
          <p className="blog-date">
            <i>{getDate(props.date)}</i>
          </p>
          <p className="blog-brief">{props.brief}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
