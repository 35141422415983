import React, { useState } from "react";

const CertificationCard = (props) => {
  const [showInfo, setShowInfo] = useState(false);

  function handleClick() {
    setShowInfo(!showInfo);
  }

  return (
    <div className="cert-card" onClick={handleClick}>
      <div className="cert-card-content">
        <div className="cert-card-text">
          <h4 className="card-header">{props.data.issuer}</h4>
          <p className="card-sub">
            <i>{props.data.title}</i>
          </p>
        </div>
        {props.data.imageName !== "" && (
          <div>
            <img
              className="cert-logo"
              alt="certification-logo"
              src={require("../images/" + props.data.imageName)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CertificationCard;
