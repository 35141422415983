const certifications = [
  {
    title: "GitHub Foundations",
    issuer: "GitHub",
    imageName: "gh.png",
    link: "https://www.credly.com/badges/fca91a45-4834-47af-a1df-7b49d2faa4ef/public_url"
  },
  {
    title: "Certified Kubernetes Application Developer",
    issuer: "Cloud Native Computing Foundation",
    imageName: "ckad.png",
    link: "https://www.credly.com/badges/1076db77-2650-485f-b7d3-5cdc479dfdef/public_url"
  },
  {
    title: "HashiCorp Certified: Terraform Associate",
    issuer: "Hashicorp",
    imageName: "terraform.png",
    link: "https://www.credly.com/badges/02c29744-ba5c-4e70-84de-b9b808c6d14f/public_url"
  },
  {
    title: "Certified Solutions Architect - Associate",
    issuer: "Amazon Web Services",
    imageName: "aws.png",
    link: "https://www.credly.com/badges/4beaa425-f449-4bb1-9772-e15d9e5f77d2/public_url"
  },
  {
    title: "Certified Developer - Associate",
    issuer: "Amazon Web Services",
    imageName: "aws.png",
    link: "https://www.credly.com/badges/b76a970a-a56b-47f0-8412-bc46928b25b0/public_url"
  }
];

export default certifications;
