import React from "react";
import SkillBar from "./SkillBar";
import skillsData from "../data/skillsData";

const Skills = () => (
  <div className="skills-section">
    <h2>Skills</h2>
    {skillsData.map((item) => (
      <SkillBar data={item} />
    ))}
  </div>
);

export default Skills;
