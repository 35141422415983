import React from "react";
import Layout from "../components/Layout";
import ContentTitle from "../components/ContentTitle";

import interestsData from "../data/interestsData";

const About = () => (
  <Layout>
    <ContentTitle title="About" />
    <div className="content-body">
      <h3>Who</h3>
      <p>Hi! I'm Kyle. I'm a software engineer.</p>

      <h3>What</h3>
      <p>
        I have a B.S. in Computer Science from the University of Pittsburgh.{" "}
        <br />I work primarily in backend development. I'm interested in cloud
        computing, distributed systems, and occasionally call myself a full-stack
        developer.
      </p>

      <h3>Where</h3>
      <p>
        I currently live in Pittsburgh PA. But, I really love to travel, so you can occasionally find me elsewhere.
      </p>

      <h3>Some things I like</h3>
      <ul>
        {interestsData.map((i) => (
          <li key={i.item}>{i.item}</li>
        ))}
      </ul>
    </div>
  </Layout>
);

export default About;
