import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import "./static/css/index.css";

import Home from "./views/Home";
import About from "./views/About";
import Contact from "./views/Contact";
import Resume from "./views/Resume";
import Site from "./views/Site";

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/resume" component={Resume} />
        <Route path="/site" component={Site} />
        <Route path="/contact" component={Contact} />
      </Switch>
    </Router>
  </QueryClientProvider>,
  document.getElementById("root")
);
