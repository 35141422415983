import React, { useState } from "react";

const ResumeCard = (props) => {
  const [showInfo, setShowInfo] = useState(false);

  function handleClick() {
    setShowInfo(!showInfo);
  }

  return (
    <div className="resume-card" onClick={handleClick}>
      <div className="resume-card-content">
        <div className="resume-card-text">
          <h4 className="card-header">{props.data.company}</h4>
          {props.data.title.map((titleItem) => (
            <p key={titleItem} className="card-sub">
              <i>{titleItem}</i>
            </p>
          ))}
          <p className="card-sub">{props.data.location}</p>
          <p className="card-sub">{props.data.duration}</p>
        </div>
        {props.data.imageName !== "" && (
          <div>
            <img
              className="company-logo"
              alt="company-logo"
              src={require("../images/" + props.data.imageName)}
            />
          </div>
        )}
      </div>

      {showInfo ? (
        <div className="description open">
          {props.data.link !== "" ? (
            <a
              className="card-sub link"
              href={props.data.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Website
            </a>
          ) : (
            <p></p>
          )}

          {typeof props.data.description === "string" ? (
            <p className="description-paragraph">
              {props.data.description.split("\n").map((line, index, arr) => (
                <React.Fragment key={index}>
                  {line}
                  {index < arr.length - 1 && <br />}
                </React.Fragment>
              ))}
            </p>
          ) : (
            <ul className="description-list">
              {props.data.description.map((bullet, index) => (
                <li className="position-detail-bullet" key={index}>
                  {bullet}
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : (
        <div className="description"></div>
      )}

      {showInfo ? (
        <div className="expand-section open"></div>
      ) : (
        <div className="expand-section">
          <img
            className="expand-arrow"
            src={require("../images/expandarrow.png")}
            alt="arrow"
          />
        </div>
      )}
    </div>
  );
};
export default ResumeCard;
