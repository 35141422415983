import React, { useEffect } from "react";
import Layout from "../components/Layout";
import ContentTitle from "../components/ContentTitle";

const Contact = () => {
  useEffect(() => {
    // Function to load the LinkedIn script
    const loadLinkedInScript = () => {
      const script = document.createElement("script");
      script.src = "https://platform.linkedin.com/badges/js/profile.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      return script;
    };

    const linkedInScript = loadLinkedInScript();

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.body.removeChild(linkedInScript);
    };
  }, []); // Empty dependency array ensures this runs once on mount only

  return (
    <Layout>
      <ContentTitle title="Contact" />
      <div className="content-body">
        <p>Feel free to contact me through LinkedIn:</p>
        <div
          className="badge-base LI-profile-badge"
          data-locale="en_US"
          data-size="medium"
          data-theme="light"
          data-type="VERTICAL"
          data-vanity="kyle-thorpe"
          data-version="v1"
        >
          <a
            className="badge-base__link LI-simple-link"
            href="https://www.linkedin.com/in/kyle-thorpe?trk=profile-badge"
          >
            Kyle Thorpe
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
