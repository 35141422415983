import React from "react";
import EducationCard from "./EducationCard";

const Education = (props) => (
  <div className="education-section">
    <h2>Education</h2>
    <div className="card-section">
      {props.data.map((item) => (
        <EducationCard key={item.school} data={item} />
      ))}
    </div>
  </div>
);

export default Education;
