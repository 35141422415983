import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div id="footer">
      <div id="profile">
        <img
          id="profile-picture"
          src={require("../images/profilepicture.png")}
          alt="Me"
        />
        <h3>Kyle Thorpe</h3>
        <p>
          Hi! I'm Kyle. Thanks for coming to my site. Please browse a bit and{" "}
          <Link to="/site">check out how it's built</Link>!<br />
          If you're interested in talking, reach out to me from my{" "}
          <Link to="/contact">Contact</Link> page.
        </p>
        <a href="blog.kthorpe.co" target="_blank" rel="noopener noreferrer">
          Blog
        </a>
        &nbsp;&nbsp;&nbsp;
        <a
          href="https://www.linkedin.com/in/kyle-thorpe/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
        &nbsp;&nbsp;&nbsp;
        <a
          href="https://github.com/kjthorpe18"
          target="_blank"
          rel="noopener noreferrer"
        >
          GitHub
        </a>
      </div>
    </div>
  );
};

export default Footer;
