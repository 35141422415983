import React from "react";

const SkillBar = (props) => {
  const barStyle = {
    width: `${String(
      Math.min(100, Math.max((props.data.level / 5.0) * 100.0, 0))
    )}%`
  };

  return (
    <div className="skillbar">
      <div className="skill-title">{props.data.title}</div>
      <div className="skillbar-bar" id={props.data.type} style={barStyle}></div>
      {/* <div className="skill-level">{props.data.level}/5</div> */}
    </div>
  );
};

export default SkillBar;
