import React, { useState } from "react";

const EducationCard = (props) => {
  const [showInfo, setShowInfo] = useState(false);

  function handleClick() {
    setShowInfo(!showInfo);
  }

  return (
    <div className="education-card" onClick={handleClick}>
      <div className="education-card-content">
        <div className="education-card-text">
          <h4 className="card-header">{props.data.school}</h4>
          <p className="card-sub">
            <i>{props.data.degree}</i>
          </p>
          <p className="card-sub">{props.data.location}</p>
          <p className="card-sub">{props.data.duration}</p>
        </div>

        {props.data.imageName !== "" && (
          <div>
            <img
              className="school-logo"
              alt="school-logo"
              src={require("../images/" + props.data.imageName)}
            />
          </div>
        )}
      </div>
      {showInfo ? (
        <div className="description open" id={props.data.school}>
          <ul className="description-list">
            {props.data.description.map((bullet) => (
              <li key={bullet}>{bullet}</li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="description"></div>
      )}

      {showInfo ? (
        <div className="expand-section open"></div>
      ) : (
        <div className="expand-section">
          <img
            className="expand-arrow"
            src={require("../images/expandarrow.png")}
            alt="arrow"
          />
        </div>
      )}
    </div>
  );
};

export default EducationCard;
