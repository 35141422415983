const interestsData = [
  {
    item: "Rock climbing, weightlifting, biking, & hiking",
    image: "exercise"
  },
  {
    item: "History, science, & technology",
    image: "history"
  },
  {
    item: "Coffee & coffee shops",
    image: "coffee"
  },
  {
    item: "Live Music (and recorded music)",
    image: "music"
  },
  {
    item: "Books & Podcasts",
    image: "books"
  },
  {
    item: "My classic motorcycle",
    image: "motorcycle"
  }
];

export default interestsData;
