import React from "react";
import Layout from "../components/Layout";
import ContentTitle from "../components/ContentTitle";
import Blog from "../components/Blog";
import BlogCard from "../components/BlogCard";

const Home = () => (
  <Layout>
    <ContentTitle title="Home" />
    <div className="content-body">
      <p>
        Welcome to my portfolio site! This is made to tell you a bit about me,
        my experience, and my skills. Please have a look around!
      </p>
      <div>
        <h3>Blog Posts</h3>
        <Blog />
      </div>
      <div className="card-section">
        <h3>Other things:</h3>
        <BlogCard
          title="Exercise and Type 1 Diabetes"
          brief="Exercise is an essential part of our physical and mental health. Regular exercise can help us control weight, improve sleep quality, keep the immune system strong..."
          date="Sat Dec 10 2022"
          link="https://medium.com/@kjthorpe18/exercise-and-type-1-diabetes-7627df062331"
          imgUrl="https://miro.medium.com/max/1180/0*-zKBwDSGx8MRhPIe"
        />
        <BlogCard
          title="The Plague of Justinian"
          brief="Constantinople in the year 541 CE was one of the largest cities in the world; a bustling commercial center where spices and silk from India and China, olive oil and wine from Italy, and grain from Alexandria..."
          date="Wed Jun 08 2022"
          link="https://medium.com/@kjthorpe18/the-plague-of-justinian-6b23a714c72a"
          imgUrl="https://miro.medium.com/max/1400/0*zOfxGXClDjQVSDql"
        />
      </div>
    </div>
  </Layout>
);

export default Home;
