import React from "react";
import Layout from "../components/Layout";
import ContentTitle from "../components/ContentTitle";

const Site = () => (
  <Layout>
    <ContentTitle title="How this Site is Built" />
    <div className="content-body">
      This site is build using:
      <ul>
        <li>React frontend</li>
        <li>AWS API Gateway & Lambda backend</li>
        <li>PostgreSQL DB running on an EC2 instance for data</li>
        <li>Amazon S3 for images</li>
        <li>GitHub Actions for CI/CD</li>
      </ul>
    </div>
  </Layout>
);

export default Site;
