const education = [
  {
    school: "University of Pittsburgh",
    degree: "Bachelor of Science in Computer Science",
    location: "Pittsburgh, PA",
    duration: "Aug 2016 - April 2020",
    description: ["GPA: 3.85", "Dean's List 2016-2020", "Summa cum laude"],
    imageName: "pittscript.png"
  },
  {
    school: "INSA Lyon",
    degree: "Study Abroad Program",
    location: "Lyon, France",
    duration: "May 2018 - June 2018",
    description: [
      "Collaborated with a team of students to create an Internet of Things connected device using Arduino boards, Bluetooth sensors, web server, and web site dedicated to personal health and promotion of public transit.",
      "Presented functioning prototype to faculty and provided educational model for future students in the program"
    ],
    imageName: "insalogo.png"
  }
];

export default education;
