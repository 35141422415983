const skills = [
  {
    title: "Java",
    level: "4.5",
    type: "language"
  },
  {
    title: "Elixir",
    level: "3.5",
    type: "language"
  },
  {
    title: "Python",
    level: "3",
    type: "language"
  },
  {
    title: "Terraform",
    level: "3.7",
    type: "language"
  },
  {
    title: "JavaScript",
    level: "3",
    type: "language"
  },
  {
    title: "Git",
    level: "4.9",
    type: "tool"
  },
  {
    title: "Amazon Web Services",
    level: "4",
    type: "tool"
  },
  {
    title: "Kubernetes",
    level: "4",
    type: "tool"
  },
  {
    title: "Docker",
    level: "3.5",
    type: "tool"
  },
  {
    title: "Spring Boot",
    level: "3.9",
    type: "framework"
  },
  {
    title: "React",
    level: "2",
    type: "framework"
  },
  {
    title: "Ember.js",
    level: "2",
    type: "framework"
  },
  {
    title: "GitHub",
    level: "4.9",
    type: "collaboration"
  },
  {
    title: "Jira/Confluence",
    level: "4.9",
    type: "collaboration"
  },
  {
    title: "Agile/Scrum",
    level: "4",
    type: "collaboration"
  }
];

export default skills;
