import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <nav id="navbar">
      <ul>
        <li key="Kyle Thorpe" id="home">
          <Link to="/">Kyle Thorpe</Link>
        </li>
        <li key="About">
          <Link to="/about">About</Link>
        </li>
        <li key="Resume">
          <Link to="/resume">Resume</Link>
        </li>
        <li key="Blog">
          <a
            href="https://blog.kthorpe.co/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </a>
        </li>
        <li key="GitHub">
          <a
            href="https://github.com/kjthorpe18"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
          </a>
        </li>
        <li key="Contact">
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
