import React from "react";
import VolunteerCard from "./VolunteerCard";

const Volunteer = (props) => {
  return (
    <div>
      <h2>{props.title}</h2>
      <div className="card-section">
        {props.data.map((position) => (
          <VolunteerCard key={position.company} data={position} />
        ))}
      </div>
    </div>
  );
};

export default Volunteer;
