const workExperience = [
  {
    company: "Proofpoint, Inc.",
    title: ["Software Engineer I, II, III"],
    location: "Pittsburgh, PA",
    duration: "July 2020 - Present",
    description: [
      "Led effort to migrate existing systems from EC2 to Kubernetes, including modifying applications and infrastructure",
      "Developed a System for Cross-domain Identity Management (SCIM) compliant server to allow customers to integrate various identity providers with our platform",
      "Led the initiative to upgrade our organization's core project dependencies to meet security standards, and enable other teams to maintain focus on core projects",
      "Split time between teams to assist on a strategic project and help organization meet a key product release deadline",
      "Frequently paired with Product Managers, UX/UI Designers, Customer Support and other stakeholders to plan upcoming projects",
      "Frequently planned and supported releases of multiple components to production environments",
      "Advocated for developer experience and clean code, and focused on reducing technical debt to accelerate and fortify customer-facing development"
    ],
    imageName: "pfpt.png",
    link: ""
  },
  {
    company: "Proofpoint, Inc.",
    title: ["Front-End Development Intern"],
    location: "Pittsburgh, PA",
    duration: "May 2019 - Aug 2019",
    description: [
      "Collaborated with front-end development and content teams to produce security education software used by thousands of companies worldwide",
      "Completed issues contributing to team's success with technologies such as JavaScript/TypeScript, React, and Git",
      "Delivered an application to monitor various RabbitMQ queues, allowing team to streamline development process for a new feature"
    ],
    imageName: "pfpt.png",
    link: ""
  },
  {
    company: "Wombat Security, a Division of Proofpoint, Inc.",
    title: ["Quality Assurance Automation Intern"],
    location: "Pittsburgh, PA",
    duration: "Sep 2018 - November 2018",
    description: [
      "Wrote and debugged automated tests using Java, TestNG, and Selenium WebDriver",
      "Developed features and resolved bugs contributing to the success of the front-end development and automation teams",
      "Restored a neglected suite of daily-run automated tests to passing status, contributing to QA department goals"
    ],
    imageName: "wombatpfpt.png",
    link: ""
  },
  {
    company: "Wombat Security Technologies",
    title: ["Technical Customer Support Intern"],
    location: "Pittsburgh, PA",
    duration: "Jan 2018 - April 2018",
    description:
      // eslint-disable-next-line
      "This was the first rotation of a three semester co-op while I was in college. In the time I spent with customer support, I learned \
      the ins and outs of the product that our company created and the issues that customers faced with it. I also learned how support, \
      product management, and developers interact on a day to day basis. I also triaged bugs reported by customers, wrote bug tickets, \
      and created knowledge base articles to document internal processes.\n\n\
      \
      The main project of my time in customer support was an effort to reduce long-lived support tickets. By following up with \
      developers and customers, I was able to reduce the backlog of a project by 75%, helping the department reach its goal.",
    imageName: "wombat.png",
    link: ""
  }
];

export default workExperience;
