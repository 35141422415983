const volunteerExperience = [
  {
    company: "Thorpe Merit Scholarship",
    title: ["Founder"],
    duration: "May 2021 - Present",
    description: [
      "Organized and funded an annual scholarship awarded to an exceptional Western Wayne High School graduate",
      "Reviewed essays and applications to select a recipient based on academics, extracurricular activities, community involvement, and financial need"
    ],
    imageName: "ww.png",
    link: ""
  },
  {
    company: "Incline Consulting Group",
    title: ["Consultant", "Project Manager"],
    duration: "Jan 2019 - April 2020",
    description: [
      "Student organization providing pro-bono technical consulting services for non-profits in the Pittsburgh Area",
      "Improved operations, efficiency, and strategy of non-profits, allowing them to serve community effectively",
      "Managed a team of 7 students to prioritize project objectives and ensure deliverables met client’s expectations",
      "Performed an audit on client’s data security practices and provided an educational presentation for current and future employees on how to best protect user data and prevent a data breach"
    ],
    imageName: "inclinelogo.png",
    link: "http://www.pittscno.com/"
  },
  {
    company: "PittServes SCI Ecuador",
    title: ["Volunteer"],
    duration: "March 2018",
    description: [
      "Collaborated with a team of 12 students and administrators to assess and repair a computer lab",
      "Taught over 60 nine to twelve-year-old students Microsoft Office and 30 seven to nine-year-old students fundamental English"
    ],
    imageName: "",
    link: ""
  }
];

export default volunteerExperience;
