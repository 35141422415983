import React from "react";
import CertificationCard from "./CertificationCard";

const CertificationSection = (props) => {
  return (
    <div>
      <h2>{props.title}</h2>
      <div className="card-section">
        {props.data.map((cert) => (
          <CertificationCard key={cert.title} data={cert} />
        ))}
      </div>
    </div>
  );
};

export default CertificationSection;
